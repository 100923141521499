import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert2';
import 'rxjs/add/observable/timer';

import { RegisterServiceService } from '../../../service/register-service.service';
import { UploadServiceService } from '../../../service/upload-service.service';

@Component({
	selector: 'app-self-registration-profile',
	templateUrl: './self-registration-profile.component.html',
	styleUrls: ['./self-registration-profile.component.scss']
})
export class SelfRegistrationProfileComponent implements OnInit {
	language: string;
	keywords: any;
	typeRegister = 'register-profile';
	frmRegister: FormGroup;
	frmRegisterInfo: FormGroup;
	sumitted = false;
	sumittedCode = false;
	phonePattern = /^\d+$/;
	errorMsg: any;
	cfmCodeError: string;
	code: string;
	code1: string;
	code2: string;
	code3: string;
	code4: string;
	code5: string;
	phoneNumber: string;
	hasPhoneNumber: string;
	hasCode: string;
	certification: any = [];
	frontIdImage: any = [];
	fronId: any = [];
	frmInformation: any = [];
	backIdImage: any = [];
	backId: any = [];
	submitted = false;
	errCheckAgree: string;
	profile: any;
	hasProfile: Profile = new Profile();
	checkIdTypeValue: string;
	countDown;
	counter = 0;
	tick = 1000;
	profileError: any;
	certId: any;
	today = Date.now();
	fixedTimezone = this.today;
	isInvalidDocNumber = false
	invalidDocNumberMsg = ''
	constructor(
		private router: Router,
		private registerServiceService: RegisterServiceService,
		private fileService: UploadServiceService
	) { }

	ngOnInit() {
        this.language = localStorage.getItem('language');
        this.keywords = JSON.parse(localStorage.getItem('keyword_object'));
        const confirmFinish = localStorage.getItem('confirmFinish');
        if (confirmFinish) {
            localStorage.removeItem('hasPhoneNumber');
            localStorage.removeItem('hasCode');
            localStorage.removeItem('hasProfile');
            localStorage.removeItem('confirmFinish');
            this.hasPhoneNumber = '';
            this.hasCode = '';
            this.phoneNumber = '';
        }
        $('#confirmCode, #inputInfor, #confirmation').addClass('d-none');

        this.hasPhoneNumber = localStorage.getItem('hasPhoneNumber');
        if (this.hasPhoneNumber) {
            $('#confirmCode').removeClass('d-none');
            $('#inputPhoneNumber, #inputInfor, #confirmation').addClass('d-none');
            $('.step1').removeClass('active');
            $('.step2').addClass('active');

            this.phoneNumber = this.hasPhoneNumber;
        }
        this.hasCode = localStorage.getItem('hasCode');
        if (this.hasPhoneNumber && this.hasCode) {
            $('#inputInfor').removeClass('d-none');
            $('#inputPhoneNumber, #confirmCode, #confirmation').addClass('d-none');
            $('.step1, .step2').removeClass('active');
            $('.step3').addClass('active');
        }
        this.frmRegister = new FormGroup({
            phoneNumber: new FormControl('', [
                Validators.required,
                Validators.pattern(this.phonePattern),
                Validators.minLength(9),
                Validators.maxLength(15)
            ])
        });
        const conRegisterBackIDPhotoUrl = (this.frmRegisterInfo = new FormGroup({
            name: new FormControl('', Validators.required),
            certId: new FormControl('', Validators.required),
            documentNumber: new FormControl('', Validators.required),
            gender: new FormControl('', Validators.required),
            dateOfBirth: new FormControl('', Validators.required),
            frontIDPhotoUrl: new FormControl('', Validators.required),
            backIDPhotoUrl: new FormControl('') // Initialize without validators
        }));

        // Listen to changes on certId and update validation for backIDPhotoUrl
        this.frmRegisterInfo.get('certId').valueChanges.subscribe((value) => {
            if (Number(value) !== 3) {
                this.frmRegisterInfo.get('backIDPhotoUrl').setValidators(Validators.required);
            } else {
                this.frmRegisterInfo.get('backIDPhotoUrl').clearValidators();
            }
            // Re-evaluate the control's validation state
            this.frmRegisterInfo.get('backIDPhotoUrl').updateValueAndValidity();
        });
        $(function () {
            $('#documentNumber, #name').keypress(function (event) {
                // console.log('555');
                const ew = event.which;
                if (ew === 32) {
                    return true;
                }
                if (48 <= ew && ew <= 57) {
                    return true;
                }
                if (65 <= ew && ew <= 90) {
                    return true;
                }
                if (97 <= ew && ew <= 122) {
                    return true;
                }
                if (86 <= ew && ew <= 17) {
                    return true;
                }
                return false;
            });
        });

        $('.inputs').keyup(function () {
            const value = (<HTMLInputElement>event.target).value;
            const maxLength = (<HTMLInputElement>event.target).maxLength;
            if (value.length === maxLength) {
                const $next = $(this).next('.inputs');
                if ($next.length) {
                    $(this).next('.inputs').focus();
                } else {
                    $(this).blur();
                }
            } else {
                const $prev = $(this).prev('.inputs');
                if ($prev.length) {
                    $(this).prev('.inputs').focus();
                } else {
                    $(this).blur();
                }
            }
        });

        this.getCertification();
        const getProfile = localStorage.getItem('hasProfile');
        this.hasProfile = getProfile !== null ? JSON.parse(getProfile) : new Profile();
        if (Object.keys(this.hasProfile).length) {
            this.certId = this.certification.filter((x) => x.certId === this.hasProfile.certId);
            for (const i of this.certId) {
                this.hasProfile.certId = i.certName;
            }
            $('#confirmation').removeClass('d-none');
            $('#inputPhoneNumber, #confirmCode, #inputInfor').addClass('d-none');
            $('.step1, .step2, .step3').removeClass('active');
            $('.step4').addClass('active');
        }
    }

	onPaste(event: ClipboardEvent) {
		const clipboardData = event.clipboardData;
		const pastedText = clipboardData.getData('text');
		const validCharacters = /^[a-zA-Z\s]*$/;
		if (validCharacters.test(pastedText) === true) {
			return true;
		} else {
			return false;
		}


	}

	/**
	 *  Validation form register
	 */
	get frmValidate() {
		return this.frmRegister.controls;
	}

	/**
	 *  Validation form register
	 */
	get f() {
		return this.frmRegisterInfo.controls;
	}

	/**
	 *  Translate
	 */
	preventTranslate(keyword) {
		return this.keywords[keyword]
			? this.keywords[keyword][this.language]
			: keyword;
	}

	requestCode(e) {
		this.sumitted = true;
		this.phoneNumber = this.frmRegister.get('phoneNumber').value;

		if (this.phoneNumber === '' || this.phoneNumber === undefined) {
			return;
		}
		if (this.hasPhoneNumber) {
			this.phoneNumber = this.hasPhoneNumber;
		}

		this.registerServiceService.requestCode({phoneNumber: this.phoneNumber, type: this.typeRegister}).subscribe(
			data => {
				this.countDownMin();
				$('#confirmCode').removeClass('d-none');
				$('#inputPhoneNumber, #inputInfor').addClass('d-none');
				$('.step1, .step4, .step3').removeClass('active');
				$('.step2').addClass('active');
				this.code = '';
				this.code1 = '';
				this.code2 = '';
				this.code3 = '';
				this.code4 = '';
				this.code5 = '';
				localStorage.setItem('hasPhoneNumber', this.phoneNumber);
				this.cfmCodeError = '';
				this.sumittedCode = false;
			},
			err => {
				this.phoneNumber = '';
				this.errorMsg = err.error.message;
			}
		);
	}

	countDownMin() {
		// tslint:disable-next-line:no-unused-expression
		this.countDown;
		this.counter = 120;
		this.tick = 1000;
		this.countDown = Observable.timer(0, this.tick)
			.take(this.counter)
			.map(() => --this.counter);
	}

	/**
	 *  Submit confirm code
	 */
	confirmCode(inputCode, inputCode1, inputCode2, inputCode3, inputCode4, inputCode5) {
		this.hasPhoneNumber = localStorage.getItem('hasPhoneNumber');
		this.sumittedCode = true;
		if (inputCode.value === '' && inputCode1.value === '' && inputCode2.value === '' && inputCode3.value === '' && inputCode4.value === '' && inputCode5.value === '') {
			return;
		}

		const body = {
			code: `${inputCode.value}${inputCode1.value}${inputCode2.value}${inputCode3.value}${inputCode4.value}${inputCode5.value}`,
			phoneNumber: this.hasPhoneNumber,
			action: this.typeRegister
		};
		this.registerServiceService.confirmCode(body).subscribe(
			res => {
				$('#inputInfor').removeClass('d-none');
				$('#inputPhoneNumber, #confirmCode').addClass('d-none');
				$('.step1, .step2, .step4').removeClass('active');
				$('.step3').addClass('active');
				localStorage.setItem('hasCode', this.code);
			},
			err => {
				this.cfmCodeError = err.error.message;
				console.log(err, err.errors);
				if (err.error.errors.find(e => e.field === 'phoneNumber')) {
					this.cfmCodeError = 'Phone number is invalid.';
				}
			}
		);
	}

	getCertification() {
		this.registerServiceService.getCertification().subscribe(data => {
			this.certification = data;
			if (this.hasProfile) {
				this.certId = this.certification.filter(
					x => x.certId === this.hasProfile.certId
				);
				for (const i of this.certId) {
					this.hasProfile.certId = i.certName;
				}
			}
		});
	}

	/**
	 * Upload front Id image
	 * @param event
	 * @param files
	 */
	uploadFrontId(event: any, files: FileList) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.frontIdImage = events.target.result;
				$('label[for="file-input"] .upload-icon').css('border', 'none');
				$('label[for="file-input"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.fronId = data.data[0].filename;
				this.frmRegisterInfo.controls.frontIDPhotoUrl.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}
	/**
	 * Upload back Id image
	 * @param event
	 * @param files
	 */
	uploadBackId(event: any, files: FileList) {
		// console.log('files', files);
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (events: any) => {
				this.backIdImage = events.target.result;
				$('label[for="file-input1"] .upload-icon').css('border', 'none');
				$('label[for="file-input1"] .icon').hide();
			};
			reader.readAsDataURL(event.target.files[0]);
		}
		this.fileService.uploadFile(files).subscribe(
			res => {
				const data: any = res;
				this.backId = data.data[0].filename;
				this.frmRegisterInfo.controls.backIDPhotoUrl.setValue(
					data.data[0].filename
				);
			},
			err => {
				console.log(err);
			}
		);
	}
	checkAgree() {
		if ($('#checkAgree').prop('checked') === true) {
			this.errCheckAgree = '';
		}
	}
	registerProfile() {
		this.isInvalidDocNumber = false
		this.submitted = true;
		this.phoneNumber = localStorage.getItem('hasPhoneNumber');
		if (this.frmRegisterInfo.invalid) {
			return;
		}
		if (
			this.frmRegisterInfo.invalid &&
			this.frmRegisterInfo.get('certId').value === 'ID Type'
		) {
			return;
		}
		if (!$('#checkAgree').prop('checked') === true) {
			this.invalidDocNumberMsg = 'ID number is invalid!'
			return;
		}

		// will do condition here 
		// id card 
		const numericRegex = /^\d{9}$/;
    	const alphanumericRegex = /^[a-zA-Z0-9]{9}$/;

		const idNumber = this.frmRegisterInfo.get('documentNumber').value
		const certNbr = Number(this.frmRegisterInfo.get('certId').value)
		
		if (idNumber.length < 9) {
			this.isInvalidDocNumber = true
			return 
		}
		if ([2, 41].includes(certNbr) && !numericRegex.test(idNumber)) {
			this.isInvalidDocNumber = true
			return
		}
		if ([3].includes(certNbr) && !alphanumericRegex.test(idNumber)) {
			this.isInvalidDocNumber = true
			return 
		}

		this.profile = {
            phoneNumber: this.hasPhoneNumber,
            name: this.frmRegisterInfo.get('name').value,
            certId: Number(this.frmRegisterInfo.get('certId').value),
            documentNumber: this.frmRegisterInfo.get('documentNumber').value,
            gender: this.frmRegisterInfo.get('gender').value,
            dateOfBirth: String(this.frmRegisterInfo.get('dateOfBirth').value),
            frontIDPhotoUrl: this.fronId,
            backIDPhotoUrl: Number(this.frmRegisterInfo.get('certId').value) !== 3 ? this.backId : ''
        };
		localStorage.setItem('hasProfile', JSON.stringify(this.profile));

		const getProfile = localStorage.getItem('hasProfile');
		this.hasProfile = getProfile !== null ? JSON.parse(getProfile) : new Profile();
		if (Object.keys(this.hasProfile).length) {
			this.certId = this.certification.filter(
				x => x.certId === this.hasProfile.certId
			);
			for (const i of this.certId) {
				this.hasProfile.certId = i.certName;
			}
		}
		$('#confirmation').removeClass('d-none');
		$('#inputInfor, #inputPhoneNumber, #confirmCode').addClass('d-none');
		$('.step1, .step2, .step3').removeClass('active');
		$('.step4').addClass('active');
	}

	confirmFinish(e) {
		const getProfile = localStorage.getItem('hasProfile');
		this.hasProfile = getProfile !== null ? JSON.parse(getProfile) : new Profile();

		if (!Object.keys(this.hasProfile).length) {
			this.profileError = this.preventTranslate('Profile is empty.');
			console.log('profile is empty.');
			return;
		}
		if (!this.hasProfile.phoneNumber) {
			this.profileError = this.preventTranslate('Phone number is invalid.');
			console.log('phone number is invalid.');
			return;
		}
        console.log('hasProfile', this.hasProfile);
		this.certId = this.certification.find(cert => cert.certId === this.hasProfile.certId);
		this.registerServiceService.registerProfile(this.hasProfile).subscribe(data => {
			swal(
				'',
				// tslint:disable-next-line:max-line-length
				this.preventTranslate('Your profile registration has been submitted successfully. Our team will check this application as soon as possible.'),
				'success',
			);
			localStorage.setItem('confirmFinish', 'true');
			localStorage.removeItem('hasPhoneNumber');
			localStorage.removeItem('hasCode');
			localStorage.removeItem('hasProfile');
			this.hasPhoneNumber = '';
			this.hasCode = '';
			this.phoneNumber = '';
			$('#confirmCode, #inputInfor, #confirmation').addClass('d-none');
			$('#inputPhoneNumber').removeClass('d-none');
			$('.step4').removeClass('active');
			$('.step1').addClass('active');
		},
			err => {
				this.profileError = err.error.message;
			}
		);
	}

	download(e) {
		const getProfile = localStorage.getItem('hasProfile');
		const profile = getProfile !== null ? JSON.parse(getProfile) : new Profile();

		if (Object.keys(this.hasProfile).length) {
			this.profileError = this.preventTranslate('Profile is empty.');
			return;
		}
		if (!this.hasProfile.phoneNumber) {
			this.profileError = this.preventTranslate('Phone number is invalid.');
			return;
		}

		this.certId = this.certification.filter(
			x => x.certId === this.hasProfile.certId
		);
		for (const i of this.certId) {
			this.hasProfile.certId = i.certId;
		}

		this.registerServiceService.downloadRegister(profile).subscribe(data => {
			window.open(data.link);
		}, err => {
			this.profileError = err.error.message;
		});
	}

	changePhoneNumber() {
		$('#inputPhoneNumber').removeClass('d-none');
		$('#inputInfor, #confirmCode').addClass('d-none');

		$('.step2, .step3, .step4').removeClass('active');
		$('.step1').addClass('active');

		localStorage.removeItem('hasPhoneNumber');
		this.phoneNumber = '';
		this.frmRegister.reset();
		this.errorMsg = '';
	}

	/**
	 * 855189494385 => 0189494385
	 * @param mSISDN phone number
	 */
	toLocalPhoneNumber(mSISDN: string) {
		if (typeof mSISDN === 'string') {
			mSISDN = mSISDN.slice(0, 3) === '855' ? mSISDN.slice(3) : mSISDN;
			mSISDN = mSISDN.slice(0, 1) === '0' ? mSISDN : 0 + mSISDN;
		}
		return mSISDN;
	}
}

class Profile {
	phoneNumber: string;
	name: string;
	certId: number;
	documentNumber: string;
	languageId: number;
	gender: string;
	dateOfBirth: string;
	frontIDPhotoUrl: string;
	backIDPhotoUrl: string;
}
